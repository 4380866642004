import { useMutation } from '@apollo/client';
import { Typography, Stack } from '@material-ui/core';
import { Lock as LockIcon } from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useSpectator } from '../Context';
import recoveryPasswordMutation from '../gql/recoveryPasswordMutation';

const ResetPasswordByEmail = () => {
  const alert = useAlert();
  const [loadingEmailRecovery, setLoadingEmailRecovery] = useState(false);
  const { selectedSpectator } = useSpectator();
  if (!selectedSpectator) return null;
  const [recoveryPassword] = useMutation(recoveryPasswordMutation, {
    onCompleted: () => setLoadingEmailRecovery(false),
  });
  const recoveryEmailHandler = async () => {
    setLoadingEmailRecovery(true);
    try {
      await recoveryPassword({ variables: { email: selectedSpectator.email } });
      alert.success('Email de recuperação de senha enviado');
    } catch (err) {
      alert.error(
        'Ops, ocorreu um erro ao enviar email de recuperação de senha',
      );
    } finally {
      setLoadingEmailRecovery(false);
    }
  };
  return (
    <Stack direction="column" spacing={4}>
      <Stack alignItems="center" direction="row">
        <LockIcon
          sx={{ color: 'secondary.dark', height: 16, mr: 4, width: 16 }}
        />
        <Typography color="grey.200" sx={{ fontSize: 14, fontWeight: 600 }}>
          Nova senha
        </Typography>
      </Stack>
      <Typography sx={{ color: 'grey.200', fontSize: 14 }}>
        Caso o espectador não consiga fazer por conta própria, você pode
        enviá-lo um email de recuperação de senha.
      </Typography>
      <LoadingButton
        loading={loadingEmailRecovery}
        sx={{
          alignSelf: 'flex-start',
          '&.Mui-disabled': {
            bgcolor: '#4E4E4E !important',
          },
        }}
        onClick={recoveryEmailHandler}
      >
        Enviar email de nova senha
      </LoadingButton>
    </Stack>
  );
};

export default ResetPasswordByEmail;
