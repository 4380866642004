import { useMutation, useQuery } from '@apollo/client';
import {
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core';
import { Email as EmailIcon } from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import * as yup from 'yup';
import { useState } from 'react';
import { Spectator } from '../../../interfaces/Spectator';
import { useSpectator } from '../Context';
import changeUserAnswerMutation from '../gql/changeUserAnswerMutation';
import listQuestionsQuery from '../gql/listQuestionsQuery';

const ResetRequiredAnswer = () => {
  const alert = useAlert();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { selectedSpectator, setSelectedSpectator } = useSpectator();
  const { loading, data } = useQuery(listQuestionsQuery);
  const [changeUserAnswer] = useMutation(changeUserAnswerMutation);
  const answers = data?.questions?.map((question: any) => question.tags).flat();
  const questionId = data?.questions
    ?.map((question: any) => question.id)
    .flat();
  const formik = useFormik<{ answer: string }>({
    initialValues: { answer: '' },
    validationSchema: yup.object().shape({
      answer: yup.string().required('Campo obrigatório'),
    }),
    onSubmit: async (values) => {
      try {
        setSubmitLoading(true);
        const variables = {
          input: {
            userId: selectedSpectator?.id,
            answerId: values.answer,
            questionId: questionId[0],
          },
        };
        await changeUserAnswer({ variables });
        const tagName = answers.find(
          (answer: any) => answer.id === values.answer,
        );
        const newSelectedSpectator = {
          ...selectedSpectator,
          signupAnswer: {
            ...selectedSpectator?.signupAnswer,
            tag: {
              ...selectedSpectator?.signupAnswer?.tag,
              id: values.answer,
              name: tagName.name,
            },
          },
        };
        setSelectedSpectator(newSelectedSpectator as Spectator);
        alert.success('Resposta atualizada com sucesso!');
      } catch (err) {
        alert.error('Erro ao atualizar resposta');
      } finally {
        setSubmitLoading(false);
      }
    },
  });
  const userAnswer = selectedSpectator?.signupAnswer?.tag?.name;
  if (loading) return <div>Loading</div>;
  if (!selectedSpectator) return null;
  return (
    <Stack direction="column" spacing={4}>
      <Stack alignItems="center" direction="row">
        <EmailIcon
          sx={{ color: 'secondary.dark', height: 16, mr: 4, width: 16 }}
        />
        <Typography sx={{ color: 'grey.200', fontSize: 14, fontWeight: 600 }}>
          Redefinir resposta de pergunta obrigatória.
        </Typography>
      </Stack>
      <Typography sx={{ color: 'grey.200', fontSize: 14 }}>
        Ao alterar a resposta da pergunta obrigatória, o espectador deixará de
        receber notificações relacionadas a resposta anterior. Certifique-se que
        é realmente isso que você deseja fazer.
      </Typography>
      <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
        Resposta obrigatória atual
      </Typography>
      <TextField
        placeholder={userAnswer}
        fullWidth
        InputProps={{
          readOnly: true,
        }}
        sx={{ width: 229 }}
      />
      <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
        Nova resposta obrigatória
      </Typography>
      <Select
        name="answer"
        displayEmpty
        error={!!formik.errors.answer && formik.touched.answer}
        value={formik.values.answer}
        sx={{ width: 229 }}
        onChange={formik.handleChange}
      >
        <MenuItem value="">Selecione uma resposta:</MenuItem>
        {answers.map((answer: any) => (
          <MenuItem key={answer} value={answer.id}>
            {answer.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error>
        {!!formik.errors.answer && formik.errors.answer}
      </FormHelperText>
      <LoadingButton
        loading={submitLoading}
        sx={{
          alignSelf: 'flex-start',
          '&.Mui-disabled': {
            bgcolor: '#4E4E4E !important',
          },
        }}
        onClick={() => formik.handleSubmit()}
      >
        Redefinir resposta obrigatória
      </LoadingButton>
    </Stack>
  );
};

export default ResetRequiredAnswer;
