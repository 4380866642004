import { Avatar, Divider, Stack, Typography } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { useSpectator } from '../Context';
import DeactivateAccount from './DeactivateAccount';
import FavoriteTeam from './FavoriteTeam';
import ResetPasswordByEmail from './ResetPasswordByEmail';
import ResetPasswordManually from './ResetPasswordManually';
import ResetRequiredAnswer from './ResetRequiredAnswer';

const SpectatorManagement = () => {
  const { selectedSpectator } = useSpectator();
  if (!selectedSpectator) return null;
  return (
    <Stack direction="column" sx={{ px: 6, py: '85px' }}>
      <Stack direction="column" sx={{ alignItems: 'center' }}>
        <Avatar alt="avatar" sx={{ height: 86, width: 86 }} />
        <Typography
          color="white"
          sx={{ fontSize: 16, fontWeight: 600, lineHeight: '24px', mt: 4 }}
        >
          {selectedSpectator.name}
        </Typography>
      </Stack>
      <Divider sx={{ borderColor: 'grey.500', mb: 6, mt: 5 }} />
      <Stack direction="row" alignItems="center" sx={{ mb: 6 }}>
        <Typography
          sx={{ color: 'common.white', fontSize: 20, fontWeight: 600 }}
        >
          Gestão do espectador
        </Typography>
        <InfoIcon sx={{ color: 'grey.400', height: 12, ml: 2, width: 12 }} />
      </Stack>
      <Stack
        direction="column"
        sx={{
          borderBottomWidth: 1,
          borderColor: 'grey.500',
          borderStyle: 'solid',
          borderWidth: 0,
          mb: 6,
        }}
        spacing={6}
        divider={<Divider sx={{ borderColor: 'grey.500' }} />}
      >
        <ResetPasswordByEmail />
        <ResetPasswordManually />
        <ResetRequiredAnswer />
        <FavoriteTeam />
        <DeactivateAccount />
      </Stack>
    </Stack>
  );
};

export default SpectatorManagement;
