import { useMutation, useQuery } from '@apollo/client';
import {
  Typography,
  Stack,
  Grid,
  Chip,
  Accordion,
  Box,
  Menu,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  MoreVert as OptionsIcon,
  ExpandMore,
  ExpandLess,
  Info as InfoIcon,
} from '@material-ui/icons';
import { useContext, useState } from 'react';
import { useAlert } from 'react-alert';
import { useDialog } from '../../../components/Dialog';
import { PlanStatus } from '../../../interfaces/Plan';
import { UserPlan } from '../../../interfaces/Spectator';
import { formatDateByFNS } from '../../../utils/date';
import { getFriendlyNameByPlanType } from '../../CreatePlan/Components/PaymentBonds';
import { Context } from '../Context';
import cancelUserPlanMutation from '../gql/cancelUserPlanMutation';
import nextRecurrenceQuery from '../gql/getNextSubscriptionRecurrenceQuery';

const PlanStatusName = {
  [PlanStatus.active]: 'Ativo',
  [PlanStatus.cancelled]: 'Cancelado',
  [PlanStatus.suspended]: 'Suspenso',
  [PlanStatus.preCancelled]: 'Pré-cancelado',
};

interface GetStatusFriendlyNameReturn {
  statusName: string;
  color:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
}

export const getStatusFriendlyName = (
  status: PlanStatus,
): GetStatusFriendlyNameReturn => {
  const planStatus = PlanStatusName[status];
  switch (status) {
    case PlanStatus.active:
      return {
        statusName: planStatus,
        color: 'success',
      };
    case PlanStatus.cancelled:
      return {
        statusName: planStatus,
        color: 'error',
      };
    case PlanStatus.suspended:
      return {
        statusName: planStatus,
        color: 'error',
      };
    default:
      return {
        statusName: planStatus,
        color: 'warning',
      };
  }
};

interface SpectatorSubscriptionItemProps {
  data: UserPlan;
  isLastItem: boolean;
  refetch: () => void;
  updateSpectatorStatus: (planId: string) => void;
}

const SpectatorSubscriptionItem = ({
  data,
  isLastItem,
  refetch,
  updateSpectatorStatus,
}: SpectatorSubscriptionItemProps) => {
  const { selectedSpectator } = useContext(Context);
  const { loading: loadingRecurrence, data: nextRecurrence } = useQuery(
    nextRecurrenceQuery,
    {
      variables: { userPlanId: data.id },
    },
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const alert = useAlert();
  const dialog = useDialog();
  const paymentMethod = data.userPlanHistory?.paymentHistory?.paymentMethod;
  const getLastFourDigits = (method: string) => {
    if (method !== 'pix') {
      return data.userPlanHistory?.paymentHistory?.lastFourCardDigits;
    }
    return null;
  };
  const getPaymentMethod = () => {
    const lastDigits = getLastFourDigits(paymentMethod);
    switch (paymentMethod) {
      case 'credit':
        return `Crédito final ${lastDigits}`;
      case 'debit':
        return `Débito final ${lastDigits}`;
      case 'pix':
        return 'Pix';
      default:
        return undefined;
    }
  };
  const open = Boolean(anchorEl);
  const formatDate = formatDateByFNS(data.createdAt, 'dd/MM/yyyy');
  const [cancelUserPlan] = useMutation(cancelUserPlanMutation);
  const formatedPlanPrice = `R$${String(data.plan.price.toFixed(2)).replace(
    '.',
    ',',
  )}`;
  const isMonthlyOrSeason =
    data.plan.type === 'monthly' || data.plan.type === 'season';
  const openMenuHandler = (evt: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(evt.currentTarget);
  const closeMenuHandler = () => setAnchorEl(null);
  const cancelPlanHandler = async () => {
    try {
      await cancelUserPlan({
        variables: {
          input: { planId: data.plan.id, userId: selectedSpectator?.id },
        },
      });
      await refetch();
      alert.success('Plano cancelado com sucesso!');
      updateSpectatorStatus(data.plan.id);
    } catch (err) {
      alert.error('Ocorreu um erro ao cancelar.');
    }
  };
  const { color, statusName } = getStatusFriendlyName(
    data.userPlanHistory.status,
  );
  const openCancelPlanHandler = async () => {
    closeMenuHandler();
    const confirm = await dialog.confirm({
      title: 'Você tem certeza?',
      message:
        'Ao cancelar um plano de um espectador, o seu acesso à plataforma será afetado e esta ação não poderá ser revertida.',
    });
    if (confirm) {
      cancelPlanHandler();
    }
  };
  const planIsActive = data.userPlanHistory.status === PlanStatus.active;
  if (loadingRecurrence) return <div>Carregando...</div>;
  const formatNextRecurrenceDate = nextRecurrence?.getNextSubscriptionRecurrence
    ? formatDateByFNS(
        nextRecurrence?.getNextSubscriptionRecurrence?.replace('Z', ''),
        'dd MMM yyyy',
      )
    : null;
  const allowedShowNextBilling = isMonthlyOrSeason && planIsActive;
  return (
    <Stack
      direction="column"
      spacing={4}
      sx={
        isLastItem
          ? null
          : {
              borderBottomWidth: 1,
              borderColor: 'grey.500',
              borderStyle: 'solid',
              borderWidth: 0,
              marginBottom: '32px',
              paddingBottom: '32px',
            }
      }
    >
      <Stack direction="row" spacing={6} sx={{ alignItems: 'center' }}>
        <Typography color="grey.200" sx={{ fontSize: 14, fontWeight: 600 }}>
          {data.plan.title}
        </Typography>
        <Chip
          color={color}
          label={
            <Typography sx={{ color, fontSize: 13 }}>{statusName}</Typography>
          }
          size="small"
          variant="outlined"
          sx={{ borderRadius: 1 }}
        />
      </Stack>
      <Grid container>
        <Grid item xs={2}>
          <Stack direction="column" spacing={2}>
            <Typography color="grey.300" sx={{ fontSize: 10 }}>
              ASSINADO EM
            </Typography>
            <Typography
              noWrap
              color="grey.200"
              sx={{
                color: 'grey.200',
                fontSize: 14,
                maxWidth: 150,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
            >
              <span title={data.createdAt.toLocaleString()}>{formatDate}</span>
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack direction="column" spacing={2}>
            <Typography color="grey.300" sx={{ fontSize: 10 }}>
              TIPO DO PLANO
            </Typography>
            <Typography
              noWrap
              color="grey.200"
              sx={{
                color: 'grey.200',
                fontSize: 14,
                maxWidth: 150,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
            >
              {getFriendlyNameByPlanType(data.plan.type)}
            </Typography>
          </Stack>
        </Grid>
        {isMonthlyOrSeason && (
          <Grid item xs={2}>
            <Stack direction="column" spacing={2}>
              <Typography color="grey.300" sx={{ fontSize: 10 }}>
                VALOR
              </Typography>
              <Typography
                noWrap
                color="grey.200"
                sx={{
                  color: 'grey.200',
                  fontSize: 14,
                  maxWidth: 150,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
              >
                {formatedPlanPrice}
              </Typography>
            </Stack>
          </Grid>
        )}
        {paymentMethod && (
          <Grid item xs={3}>
            <Stack direction="column" spacing={2}>
              <Typography color="grey.300" sx={{ fontSize: 10 }}>
                PAGAMENTO
              </Typography>
              <Typography
                noWrap
                color="grey.200"
                sx={{
                  color: 'grey.200',
                  fontSize: 14,
                  maxWidth: 150,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
              >
                {getPaymentMethod()}
              </Typography>
            </Stack>
          </Grid>
        )}
        <Grid item xl={2} xs={2}>
          {planIsActive && (
            <Box onClick={openMenuHandler}>
              <OptionsIcon
                data-testid="subscription-options"
                sx={{ color: 'grey.400', cursor: 'pointer' }}
              />
            </Box>
          )}
          <Menu
            anchorEl={anchorEl}
            open={open}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'center',
              vertical: 'top',
            }}
            sx={{ '.MuiMenu-paper': { bgcolor: 'common.white' } }}
            onClose={closeMenuHandler}
          >
            <Stack
              data-testid="delete-subscription-option"
              sx={{
                alignItems: 'center',
                cursor: 'pointer',
                flexDirection: 'row',
                gap: 3,
                p: 4,
              }}
              onClick={openCancelPlanHandler}
            >
              <CloseIcon sx={{ color: 'grey.300' }} />
              <Typography sx={{ color: 'grey.400', fontSize: 14 }}>
                Cancelar plano
              </Typography>
            </Stack>
          </Menu>
        </Grid>
      </Grid>
      {allowedShowNextBilling && (
        <Stack>
          <Typography sx={{ color: 'grey.300', fontSize: 10 }}>
            PRÓXIMA COBRANÇA:
          </Typography>
          <Typography sx={{ color: 'error.main', fontSize: 12 }}>
            {formatNextRecurrenceDate}
          </Typography>
        </Stack>
      )}
      <Accordion
        disableGutters
        expanded={isExpanded}
        sx={{
          border: 'none',
          boxShadow: 'none !important',
          '::before': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Stack
          direction="row"
          onClick={() => setIsExpanded((currState) => !currState)}
          sx={{ alignItems: 'center', boxShadow: 'none', cursor: 'pointer' }}
        >
          <Typography sx={{ fontSize: 12, color: 'grey.400', fontWeight: 600 }}>
            {isExpanded ? 'ver menos' : 'ver mais'}
          </Typography>
          {isExpanded ? (
            <ExpandLess sx={{ color: 'grey.400' }} />
          ) : (
            <ExpandMore sx={{ color: 'grey.400' }} />
          )}
        </Stack>
        <Stack direction="column" sx={{ mt: 6 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
            <Typography color="white" sx={{ fontSize: 14, fontWeight: 600 }}>
              Dados de cobrança
            </Typography>
            <InfoIcon
              sx={{
                color: 'grey.400',
                height: 12,
                ml: 2,
                width: 12,
              }}
            />
          </Stack>
          <Grid container>
            <Grid item xs={4}>
              <Stack direction="column" spacing={2}>
                <Typography color="grey.300" sx={{ fontSize: 10 }}>
                  CPF
                </Typography>
                <Typography
                  noWrap
                  color="grey.200"
                  sx={{
                    color: 'grey.200',
                    fontSize: 14,
                    maxWidth: 150,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                  }}
                >
                  {selectedSpectator!.cpf}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Accordion>
    </Stack>
  );
};

export default SpectatorSubscriptionItem;
