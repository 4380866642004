import { Card, Stack } from '@material-ui/core';
import { SxProps, Theme } from '@material-ui/system';
import { useContext } from 'react';
import { Spectator } from '../../../interfaces/Spectator';
import { Context } from '../Context';
import LeadsHeader from './LeadsHeader';

import SpectatorListHeader from './SpectatorListHeader';

interface PlanListProps {
  sx?: SxProps<Theme>;
  data: Spectator[];
  renderItem: (item: Spectator, index: number) => JSX.Element;
}

const SpectatorList = ({ sx, data, renderItem }: PlanListProps) => {
  const { listLeads } = useContext(Context);
  return (
    <Card sx={{ borderRadius: 2, p: 5, ...sx }}>
      {listLeads ? <LeadsHeader /> : <SpectatorListHeader />}
      {data?.map((eachItem, index) => (
        <Stack
          alignItems="center"
          direction="column"
          justifyContent="space-between"
          sx={{ mb: data.length - 1 === index ? -4 : 4, height: 66 }}
          spacing={6}
        >
          {renderItem(eachItem, index)}
        </Stack>
      ))}
    </Card>
  );
};

export default SpectatorList;
