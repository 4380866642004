import { createTheme } from '@material-ui/core';
import makeMuiButton from './factories/makeMuiButton';
import makeMuiChip from './factories/makeMuiChip';
import makeMuiCardContent from './factories/makeMuiCardContent';
import makeMuiCheckbox from './factories/makeMuiCheckbox';
import makeMuiCssBaseline from './factories/makeMuiCssBaseline';
import makeMuiInputBase from './factories/makeMuiInputBase';
import makeMuiList from './factories/makeMuiList';
import makeMuiListItem from './factories/makeMuiListItem';
import makeMuiListItemIcon from './factories/makeMuiListItemIcon';
import makeMuiMenu from './factories/makeMuiMenu';
import makeMuiMenuItem from './factories/makeMuiMenuItem';
import makeMuiOutlinedInput from './factories/makeMuiOutlinedInput';
import makeMuiPagination from './factories/muiPagination';
import makeMuiPaginationItem from './factories/makeMuiPaginationItem';
import makeMuiPaper from './factories/makeMuiPaper';
import makeMuiRadio from './factories/makeMuiRadio';
import makeMuiSwitch from './factories/makeMuiSwitch';
import makeMuiTableCell from './factories/makeMuiTableCell';

declare module '@material-ui/core/styles' {
  interface Theme {
    shape: {
      borderRadius: number;
      sizes: { small: number; medium: number; large: number };
    };
  }
  interface ThemeOptions {
    shape: {
      borderRadius: number;
      sizes: { small: number; medium: number; large: number };
    };
  }
}

const theme = createTheme({
  typography: { fontFamily: 'Poppins' },
  palette: {
    primary: { main: '#171717', light: '#9A9A9A' },
    secondary: { main: '#2F49D1', light: '#DAE0FF' },
    error: { main: '#FF545E', light: '#FFE5E6' },
    warning: { main: '#F1AE00', light: '#FFF6DE' },
    info: { main: '#8950FC', light: '#EFE7FF' },
    success: { main: '#00C572', light: '#D1FFEC'},
    
    grey: {
      600: '#141414',
      500: '#1E1E1E',
      400: '#4E4E4E',
      300: '#B6B6B6',
      200: '#E7E7E7',
      100: '#F3F3F3',
    },
  },
  shape: { borderRadius: 4, sizes: { small: 30, medium: 36, large: 44 } },
  spacing: [0, 4, 8, 12, 16, 24, 32, 40, 48, 56, 64, 96],
});

theme.components = {
  MuiButton: makeMuiButton(theme),
  MuiCardContent: makeMuiCardContent(theme),
  MuiChip: makeMuiChip(theme),
  MuiCssBaseline: makeMuiCssBaseline(theme),
  MuiInputBase: makeMuiInputBase(theme),
  MuiList: makeMuiList(),
  MuiListItem: makeMuiListItem(theme),
  MuiListItemIcon: makeMuiListItemIcon(),
  // @ts-ignore
  MuiLoadingButton: {
    defaultProps: {
      loadingPosition: 'start',
    },
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          backgroundColor: `${theme.palette.secondary.main} !important`,
          color: `${theme.palette.common.white} !important`,
        },
      },
      loadingIndicator: {
        position: 'static',
      },
      loadingIndicatorStart: {
        marginRight: theme.spacing(2),
      },
      loadingIndicatorEnd: {
        marginLeft: theme.spacing(2),
      },
    },
  },
  MuiTableCell: makeMuiTableCell(),
  MuiCheckbox: makeMuiCheckbox(theme),
  MuiMenu: makeMuiMenu(theme),
  MuiMenuItem: makeMuiMenuItem(theme),
  MuiOutlinedInput: makeMuiOutlinedInput(theme),
  MuiPaper: makeMuiPaper(theme),
  MuiPagination: makeMuiPagination(theme),
  MuiPaginationItem: makeMuiPaginationItem(theme),
  MuiRadio: makeMuiRadio(theme),
  MuiSwitch: makeMuiSwitch(theme),
  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        '*': {
          color: 'white',
        }
      }
    }
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.grey[300],
        color: `${theme.palette.grey[500]} !important`,
        fontWeight: 600,
        fontSize: 14,
        borderRadius: 3,
        margin: 2,
        '&.MuiPickersDay-today': {
          backgroundColor: theme.palette.grey[300],
          color: `${theme.palette.grey[500]} !important`,
        },
        '&.Mui-selected, &.MuiPickersDay-today.Mui-selected': {
          backgroundColor: theme.palette.secondary.main,
          color: `${theme.palette.grey[100]} !important`,
          fontWeight: 600,
        },
        '&:disabled': {
          backgroundColor: theme.palette.grey[500],
          color: `${theme.palette.grey[400]} !important`,
        },
        '&.Mui-selected&:hover, &:hover': {
          backgroundColor: theme.palette.grey[100],
          color: `${theme.palette.grey[500]} !important`,
        },
      }
    }
  },
  MuiYearPicker: {
    styleOverrides: {
      root: {
        '.PrivatePickersYear-root button': {
          backgroundColor: theme.palette.grey[300],
          color: theme.palette.grey[500],
          fontWeight: 600,
          fontSize: 14,
          borderRadius: 3,
          margin: 2,
          '&.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.grey[100],
            fontWeight: 600,
          },
          '&:disabled': {
            backgroundColor: theme.palette.grey[500],
            color: theme.palette.grey[400],
          },
          '&.Mui-selected&:hover, &:hover': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[500],
          },
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        '*': {
          color: 'white',
        }
      }
    }
  },
};

export default theme;
