import { useMutation } from '@apollo/client';
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Lock as LockIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import * as yup from 'yup';
import { useSpectator } from '../Context';
import resetPasswordMutation from '../gql/resetPasswordMutation';

const ResetPasswordManually = () => {
  const alert = useAlert();
  const [resettingPassword, setResettingPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { selectedSpectator } = useSpectator();
  const [resetPassword] = useMutation(resetPasswordMutation, {
    onCompleted: () => setResettingPassword(false),
  });
  const formik = useFormik<{ password: string }>({
    initialValues: { password: '' },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*,-./:;<=>?@[\\\]^_`{|}~]{8,}$/,
          'A senha deve conter 8 caracteres, letra maiúscula, letra minuscula, número e caracter especial.',
        )
        .required('Campo obrigatório'),
    }),
    onSubmit: async ({ password }) => {
      setResettingPassword(true);
      try {
        await resetPassword({
          variables: { input: { email: selectedSpectator!.email, password } },
        });
        alert.success('Senha redefinida com sucesso');
      } catch (err) {
        alert.error('Ops, ocorreu um erro ao redefinir senha');
      } finally {
        setResettingPassword(false);
      }
    },
  });
  if (!selectedSpectator) return null;
  return (
    <Stack direction="column" spacing={4}>
      <Stack alignItems="center" direction="row">
        <LockIcon
          sx={{ color: 'secondary.dark', height: 16, mr: 4, width: 16 }}
        />
        <Typography sx={{ color: 'grey.200', fontSize: 14, fontWeight: 600 }}>
          Redefinir senha manualmente
        </Typography>
      </Stack>
      <Typography sx={{ color: 'grey.200', fontSize: 14 }}>
        Caso o espectador não consiga fazer por conta própria, você pode
        redefinir manualmente a senha.
      </Typography>
      <TextField
        name="password"
        type={showPassword ? 'text' : 'password'}
        fullWidth
        error={!!formik.errors.password}
        value={formik.values.password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                onClick={() =>
                  setShowPassword((prevShowPassword) => !prevShowPassword)
                }
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
          placeholder: 'Preencha com a nova senha',
        }}
        onChange={formik.handleChange}
      />
      <FormHelperText error>{formik.errors.password}</FormHelperText>
      <LoadingButton
        loading={resettingPassword}
        sx={{
          alignSelf: 'flex-start',
          '&.Mui-disabled': {
            bgcolor: '#4E4E4E !important',
          },
        }}
        onClick={() => formik.handleSubmit()}
      >
        Redefinir senha
      </LoadingButton>
    </Stack>
  );
};

export default ResetPasswordManually;
